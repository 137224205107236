import React from "react";
import { FaFacebook } from 'react-icons/fa';
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaCamera } from "react-icons/fa";

export default function Footer() {
    return (
    <footer class="p-4 bg-white shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-white">
    <span class="text-sm text-vintage-dblue sm:text-center dark:text-black">© 2024 <a href="https://ericmlyons.com/" class="hover:underline">Eric Lyons!</a> All Rights Reserved.
    </span>
    <ul class="flex flex-wrap items-center mt-3 text-xl text-gray-500 dark:text-black sm:mt-0">
        <li>
            <a href="https://www.linkedin.com/in/ericmlyons/" class="mx-10 hover:underline md:mr-6" target="_blank"><FaLinkedin /><span className="sr-only">Linkedin</span></a>
        </li>
        <li>
            <a href="https://www.instagram.com/edub187/" class="mx-10 hover:underline md:mr-6" target="_blank"><FaInstagram /><span className="sr-only">Instagram</span></a>
        </li>
    </ul>
</footer>
    );
}