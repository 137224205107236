import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

var Capone = require('../../image/capone.png');
 
export function DialogCustomAnimationCapOne() {
  const [open, setOpen] = React.useState(false);
 
  const handleOpen = () => setOpen(!open);
 
  return (
    <>
      <Button onClick={handleOpen} variant="gradient" className="button-white inline-flex text-black border-0 py-2 px-6 hover:button-black hover:text-white rounded text-lg outline-black">
        Read More <span className="hidden">about my time at Capital One</span>
      </Button>
      <Dialog className="w-screen"
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <img alt="Capital One logo" src={Capone} className="p-4"/>
        <DialogHeader>More goodness arriving soon...</DialogHeader>
        <DialogBody>
        Patience. I can only produce so much content at once! Please check back soon to learn more about my adventures...
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1 button-white inline-flex text-black border-0 py-2 px-6 hover:button-black hover:text-white rounded text-lg outline-black"
          >
            <span>OK</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}