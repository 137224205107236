import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

var USAA = require('../../image/usaa.png');
 
export function DialogCustomAnimationUSAA() {
  const [open, setOpen] = React.useState(false);
 
  const handleOpen = () => setOpen(!open);
 
  return (
    <>
      <Button onClick={handleOpen} variant="gradient" className="button-white inline-flex text-black border-0 py-2 px-6 hover:button-black hover:text-white rounded text-lg outline-black">
        Read More <span className="hidden">about my time at Fidelity</span>
      </Button>
      <Dialog className="w-screen" 
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <img alt="USAA logo" src={USAA} className="p-4"/>
        <DialogHeader role="heading" aria-level="1" >USAA</DialogHeader>
        <DialogBody>
        <p className="mb-8 leading-relaxed sm:text-lg text-base">Hands down the best 5 years of my career. Having the opportunity to serve the brave men and women of the armed forces helped me rediscover my purpose, and gave me a chance to really enact changes. </p>
        <p className="mb-8 leading-relaxed sm:text-lg text-base">I managed an enablement team in the Chief Design Office, focused primarily on implementation of process in the design space. Surrounded entirely by creative folks helped me push the boundries of my own creativity in the work the team did!</p>
        <p className="mb-8 leading-relaxed sm:text-lg text-base">So what did I do here? There was so much work done, and so many opportunities to make design great. From implementing processes that ensured our designers created beautiful ACCESSIBLE experiences, to tracking design defects to bringing our work into Rally (JIRA's much more fun distance cousing), I found creative ways to lock down process within the Chief Design Office.</p>
       </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1 button-white inline-flex text-black border-0 py-2 px-6 hover:button-black hover:text-white rounded text-lg outline-black"
          >
            <span>OK</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}