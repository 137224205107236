import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

var Amc = require('../../image/amc.png');
 
export function DialogCustomAnimationAMC() {
  const [open, setOpen] = React.useState(false);
 
  const handleOpen = () => setOpen(!open);
 
  return (
    <>
      <Button onClick={handleOpen} variant="gradient" className="button-white inline-flex text-black border-0 py-2 px-6 hover:button-black hover:text-white rounded text-lg outline-black">
        Read More <span className="hidden">about my time at AMC</span>
      </Button>
      <Dialog className="w-screen"
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <img alt="AMC logo" src={Amc} className="p-4"/>
        <DialogHeader>AMC Theaters</DialogHeader>
        <DialogBody>
        <p className="mb-8 leading-relaxed sm:text-lg text-base">Okay. So this was a really cool project I had the opportunity to work on. I love movies. And I got to go to the headquarters of the country's largest movie chain! Movie posters on the walls. Popcorn in the lobby.</p>
        <p className="mb-8 leading-relaxed sm:text-lg text-base">This organization was setting forth on their journey around creating accessible experiences for their customers and associates. Specifically web and documents. I presented over several days, walking the teams through guidelines and best practices, as well as having them do hands on work as well.</p>
        <p className="mb-8 leading-relaxed sm:text-lg text-base">I walked away confident in what I had tought this team, and where they were headed. And enough popcorn to get me home...</p>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1 button-white inline-flex text-black border-0 py-2 px-6 hover:button-black hover:text-white rounded text-lg outline-black"
          >
            <span>OK</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}