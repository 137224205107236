import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import { useState } from "react";
import React from "react";

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
  } from "@material-tailwind/react";

import { DialogCustomAnimationFid } from "./Modals/ModalFid"
import { DialogCustomAnimationUSAA } from "./Modals/ModalUsaa"
import { DialogCustomAnimationCapOne } from "./Modals/ModalCapOne"
import { DialogCustomAnimationBofA } from "./Modals/ModalBofA"
import { DialogCustomAnimationPS } from "./Modals/ModalPlayStation"
import { DialogCustomAnimationGoogle } from "./Modals/ModalGoogle"
import { DialogCustomAnimationAMC } from "./Modals/ModalAMC"
import { DialogCustomAnimationCollegeboard } from "./Modals/ModalCB";
  
var Fidelity = require('../image/fidelity_color.png');
var USAA = require('../image/usaa_color.png');
var Capone = require('../image/capone_color.png');
var Bofa = require('../image/boa2.png');
var CollegeBoard = require('../image/collegeboard_color.png')
var AMC = require('../image/amc_color.png')
var Ps = require('../image/ps2.png');
var Google = require('../image/google_color.png');

export function Component() {
    const [openModal, setOpenModalFid] = useState(false);
    const [openModalUSAA, setOpenModalUSAA] = useState(false);
    const [openModalCapOne, setOpenModalCapOne] = useState(false);
    const [openModalGoogle, setOpenModalGoogle] = useState(false);
    const [openModalCollegeBoard, setOpenModalCollegeBoard] = useState(false);
    const [openModalAMC, setOpenModalAMC] = useState(false);

    return (
        <section id="experience" className="text-vintage-babyblue bg-vintage-blue">
            <div className="container mx-auto pb-12">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <div className="text-white bg-vintage-blue p-3 rounded-md md:col-span-2 lg:col-span-3"><h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-vintage-babyblue pt-24">Experience</h1>
                <p className="mb-8 leading-relaxed text-lg md:text-xl text-vintage-babyblue pt-8">Please feel free to <a href="https://ericmlyons.com/static/media/ELyons_Current_Resume.pdf" className="underline" target="_blank">download my resume</a> if you would like to learn more about the various roles I've held.
                The cards below are a slice of my experience supporting all types of companies. many which came while I was a consultant at Level Access. Great work that just doesn't fit in a resume document. Hope you enjoy!</p>
                </div>
                    <Card className="mt-4 w-96 rounded-lg">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="Fidelity logo" src={Fidelity}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" className="mb-2 text-center text-vintage-dblue">
                        Fidelity Investments
                        </Typography>
                        <Typography className="text-vintage-dblue">
                        At Fidelity, I have focused building a strong accessibility program within Workplace Investing, and ensuring accessibility is captured throughout the experience lifecycle, from design to development all the way to the hands of the customer!
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <Button className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg" onClick={() => setOpenModalFid(true)} type="button">Read More <span className="sr-only">about Fidelity Investments</span></Button>
                        </CardFooter>
                        <Modal dismissible show={openModal} onClose={() => setOpenModalFid(false)} className="bg-vintage-blue">
                            <Modal.Header className="bg-white"><span className=" text-vintage-dblue">Fidelity</span></Modal.Header>
                            <Modal.Body className="bg-white">
                                <Typography>
                                <div className="text-vintage-dblue">
                                    <p className="italic pb-2">Fidelity is a large organization with over 70K employees, made up of two main business units: Workplace Investing and
                                    Personal Investing. Lead accessibility efforts for Workplace Investing.</p>
                                    <ul className="list-disc">
                                        <li>Led digital accessibility efforts for a $1B product suite, improving the design and development processes, managing risks and
                                        developing mitigation strategies, for an application used by over 20, 000 clients, including Fortune 500 companies.</li>
                                        <li>Worked with cross-functional teams from research, to design, to development to integrate accessibility into product lifecycles,
                                        resulting in a 55% increase in accessibility compliance and streamlined project delivery.</li>
                                        <li>Collaborated with UXD to deploy a FIGMA Accessibility Annotation kit, improving the transition of accessibility information from
                                        design to development, and resulting in a reduction of time spent between design and development.</li>
                                        <li>Worked directly with multiple stakeholders and provided project oversight on a multi-year $750 million plus value contract, to
                                        implement reporting and risk mitigation plans that eliminated 1,000 accessibility defects within a year, and creating a more
                                        seamless experience for customers and associate's.</li>
                                        <li>Provided strategic direction and multi-year objectives and road maps for the Accessibility Center of Excellence team.
                                        Managed team priorities and workstreams, supervised process updates to stakeholders, managed budgets for the team and
                                        external vendor support.</li>
                                    </ul>
                                </div>
                                </Typography>
                            </Modal.Body>
                            <Modal.Footer className="bg-white">
                                <Button onClick={() => setOpenModalFid(false)} className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg">Okay</Button>
                            </Modal.Footer>
                        </Modal>
                    </Card>
                    <Card className="mt-4 w-96 rounded-lg">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="USAA logo" src={USAA}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" className="mb-2 text-center text-vintage-dblue">
                        USAA
                        </Typography>
                        <Typography className="text-vintage-dblue">
                        At USAA, I led a design enablement team that focused on providing our centralized UX teams with the tools, proccesses and support that needed to create best in class, accessible experiences. So much goodness at this place. The Chief Design Office was a collection of some of the most creative individuals I've ever been around. It reignited my creative passion!
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <Button className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg" onClick={() => setOpenModalUSAA(true)} type="button">Read More <span className="sr-only">about USAA</span></Button>
                        </CardFooter>
                        <Modal dismissible show={openModalUSAA} onClose={() => setOpenModalUSAA(false)}>
                            <Modal.Header className="bg-white"><span className=" text-vintage-dblue">USAA</span></Modal.Header>
                            <Modal.Body className="bg-white">
                                <div className="space-y-6 text-vintage-dblue">
                                <p className="italic pb-2">USAA is a medium to large financial institution with a centralized corporate design function supported by the Chief Design Office
(CDO). The CDO's Design Enablement and Operations teams provide strategic support and operations management to over
270 UX designers and design teams across various projects.</p>
                                    <ul className="list-disc">
                                        <li>Directed design operations for a centralized design function supporting over 270 UX designers, establishing a review process
that resulted in a 75% reduction in defects found in experiences and the achievement of a 100% growth in the accessibility of
Design System components.</li>
                                        <li>Led workshops and synthesized feedback to drive product and service enhancements, ensuring alignment with organizational
                                        goals and design standards.</li>
                                        <li>Created the Accessibility for Designers program, empowering associate's with design tools and techniques that resulted in a
                                        50% reduction in review defects, enhancing CDO's service delivery.</li>
                                        <li>Introduced an accessibility toolkit into Figma, reducing design to development transition time by 50% and improving team
                                        collaboration.</li>
                                    </ul>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="bg-white">
                                <Button onClick={() => setOpenModalUSAA(false)} className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg">Okay</Button>
                            </Modal.Footer>
                        </Modal>
                    </Card>
                    <Card className="mt-4 w-96 rounded-lg">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="Capital One logo" src={Capone}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" className="mb-2 text-center text-vintage-dblue">
                        Capital One
                        </Typography>
                        <Typography className="text-vintage-dblue">
                        I supported Capital One as a client while I was at Level Access, with a focus around accessibility and enablement. It was here that I got a chance to really see how to bring about change at such a large organization. I know Capital One continues to chart a path forward in the accessibility space, and I'm glad I got to be a part of it.
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <Button className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg" onClick={() => setOpenModalCapOne(true)} type="button">Read More <span className="sr-only">about Fidelity Investments</span></Button>
                        </CardFooter>
                        <Modal dismissible show={openModalCapOne} onClose={() => setOpenModalCapOne(false)} className="bg-vintage-blue">
                            <Modal.Header className="bg-white"><span className=" text-vintage-dblue">Capital One</span></Modal.Header>
                            <Modal.Body className="bg-white">
                                <Typography>
                                <div className="text-vintage-dblue">
                                    <p className="italic pb-2">Capital One is a large organization that supports clients in the financial space. I supported UX and UXD efforts.</p>
                                    <p>Still typing...</p>
                                    {/* 
                                    <ul className="list-disc">
                                        <li>Led digital accessibility efforts for a $1B product suite, improving the design and development processes, managing risks and
                                        developing mitigation strategies, for an application used by over 20, 000 clients, including Fortune 500 companies.</li>
                                        <li>Worked with cross-functional teams from research, to design, to development to integrate accessibility into product lifecycles,
                                        resulting in a 55% increase in accessibility compliance and streamlined project delivery.</li>
                                        <li>Collaborated with UXD to deploy a FIGMA Accessibility Annotation kit, improving the transition of accessibility information from
                                        design to development, and resulting in a reduction of time spent between design and development.</li>
                                        <li>Worked directly with multiple stakeholders and provided project oversight on a multi-year $750 million plus value contract, to
                                        implement reporting and risk mitigation plans that eliminated 1,000 accessibility defects within a year, and creating a more
                                        seamless experience for customers and associate's.</li>
                                        <li>Provided strategic direction and multi-year objectives and road maps for the Accessibility Center of Excellence team.
                                        Managed team priorities and workstreams, supervised process updates to stakeholders, managed budgets for the team and
                                        external vendor support.</li>
                                    </ul>
                                    */}
                                </div>
                                </Typography>
                            </Modal.Body>
                            <Modal.Footer className="bg-white">
                                <Button onClick={() => setOpenModalCapOne(false)} className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg">Okay</Button>
                            </Modal.Footer>
                        </Modal>
                    </Card>
                    <Card className="mt-4 w-96 rounded-lg">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="Google logo" src={Google}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" className="mb-2 text-center text-vintage-dblue">
                        Google
                        </Typography>
                        <Typography className="text-vintage-dblue">
                        This was an outstanding opportunity to travel to one of the coolest campuses I'd ever been to train some of the smartest developers in the world!
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <Button className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg" onClick={() => setOpenModalGoogle(true)} type="button">Read More <span className="sr-only">about Fidelity Investments</span></Button>
                        </CardFooter>
                        <Modal dismissible show={openModalGoogle} onClose={() => setOpenModalGoogle(false)} className="bg-vintage-blue">
                            <Modal.Header className="bg-white"><span className=" text-vintage-dblue">Google</span></Modal.Header>
                            <Modal.Body className="bg-white">
                                <Typography>
                                <div className="text-vintage-dblue">
                                    <p className="italic pb-2">Google is a large organization that supports multiple initiatives. If you're curious about what they do, just...Google it...</p>
                                    {/* 
                                    <ul className="list-disc">
                                        <li>Led digital accessibility efforts for a $1B product suite, improving the design and development processes, managing risks and
                                        developing mitigation strategies, for an application used by over 20, 000 clients, including Fortune 500 companies.</li>
                                        <li>Worked with cross-functional teams from research, to design, to development to integrate accessibility into product lifecycles,
                                        resulting in a 55% increase in accessibility compliance and streamlined project delivery.</li>
                                        <li>Collaborated with UXD to deploy a FIGMA Accessibility Annotation kit, improving the transition of accessibility information from
                                        design to development, and resulting in a reduction of time spent between design and development.</li>
                                        <li>Worked directly with multiple stakeholders and provided project oversight on a multi-year $750 million plus value contract, to
                                        implement reporting and risk mitigation plans that eliminated 1,000 accessibility defects within a year, and creating a more
                                        seamless experience for customers and associate's.</li>
                                        <li>Provided strategic direction and multi-year objectives and road maps for the Accessibility Center of Excellence team.
                                        Managed team priorities and workstreams, supervised process updates to stakeholders, managed budgets for the team and
                                        external vendor support.</li>
                                    </ul>
                                    */}
                                    <p>Still typing...</p>
                                </div>
                                </Typography>
                            </Modal.Body>
                            <Modal.Footer className="bg-white">
                                <Button onClick={() => setOpenModalGoogle(false)} className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg">Okay</Button>
                            </Modal.Footer>
                        </Modal>
                    </Card>
                    <Card className="mt-4 w-96 rounded-lg">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="College Board logo" src={CollegeBoard}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" className="mb-2 text-center text-vintage-dblue">
                        College Board
                        </Typography>
                        <Typography className="text-vintage-dblue">
                        College Board reaches more than 7 million students a year, helping them navigate the path from high school to college and career. I provided their designers and developers training as a consultant while at Level Access. Early on, loved seeing how serious this organization took accessibility, and am glad I was a part of it.
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <Button className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg" onClick={() => setOpenModalCollegeBoard(true)}>Read More <span className="sr-only">about College Board</span></Button>
                        </CardFooter>
                        <Modal dismissible show={openModalCollegeBoard} onClose={() => setOpenModalCollegeBoard(false)} className="bg-vintage-blue">
                            <Modal.Header className="bg-white"><span className=" text-vintage-dblue">College Board</span></Modal.Header>
                            <Modal.Body className="bg-white">
                                <Typography>
                                <div className="text-vintage-dblue">
                                    <p className="italic pb-2">College Board is a medium sized, mission-driven, not-for-profit organization that connects students to college success. I provided training as a consultant.</p>
                                    {/* 
                                    <ul className="list-disc">
                                        <li>Led digital accessibility efforts for a $1B product suite, improving the design and development processes, managing risks and
                                        developing mitigation strategies, for an application used by over 20, 000 clients, including Fortune 500 companies.</li>
                                        <li>Worked with cross-functional teams from research, to design, to development to integrate accessibility into product lifecycles,
                                        resulting in a 55% increase in accessibility compliance and streamlined project delivery.</li>
                                        <li>Collaborated with UXD to deploy a FIGMA Accessibility Annotation kit, improving the transition of accessibility information from
                                        design to development, and resulting in a reduction of time spent between design and development.</li>
                                        <li>Worked directly with multiple stakeholders and provided project oversight on a multi-year $750 million plus value contract, to
                                        implement reporting and risk mitigation plans that eliminated 1,000 accessibility defects within a year, and creating a more
                                        seamless experience for customers and associate's.</li>
                                        <li>Provided strategic direction and multi-year objectives and road maps for the Accessibility Center of Excellence team.
                                        Managed team priorities and workstreams, supervised process updates to stakeholders, managed budgets for the team and
                                        external vendor support.</li>
                                    </ul>
                                    */}
                                    <p>Still typing...</p>
                                </div>
                                </Typography>
                            </Modal.Body>
                            <Modal.Footer className="bg-white">
                                <Button onClick={() => setOpenModalCollegeBoard(false)} className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg">Okay</Button>
                            </Modal.Footer>
                        </Modal>
                    </Card>
                    <Card className="mt-4 w-96 rounded-lg">
                        <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        alt="AMC Theaters logo" src={AMC}/>
                        </CardHeader>
                        <CardBody>
                        <Typography variant="h5" className="mb-2 text-center text-vintage-dblue">
                        AMC Theaters
                        </Typography>
                        <Typography className="text-vintage-dblue">
                        What happens when one of the biggest movie chains in the country takese the right step towards being a more inclusive company? My chance to train them!
                        </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                        <Button className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg" onClick={() => setOpenModalAMC(true)}>Read More <span className="sr-only">about College Board</span></Button>
                        </CardFooter>
                        <Modal dismissible show={openModalAMC} onClose={() => setOpenModalAMC(false)} className="bg-vintage-blue">
                            <Modal.Header className="bg-white"><span className=" text-vintage-dblue">College Board</span></Modal.Header>
                            <Modal.Body className="bg-white">
                                <Typography>
                                <div className="text-vintage-dblue">
                                    <p className="italic pb-2">College Board is a medium sized, mission-driven, not-for-profit organization that connects students to college success. I provided training as a consultant.</p>
                                    {/* 
                                    <ul className="list-disc">
                                        <li>Led digital accessibility efforts for a $1B product suite, improving the design and development processes, managing risks and
                                        developing mitigation strategies, for an application used by over 20, 000 clients, including Fortune 500 companies.</li>
                                        <li>Worked with cross-functional teams from research, to design, to development to integrate accessibility into product lifecycles,
                                        resulting in a 55% increase in accessibility compliance and streamlined project delivery.</li>
                                        <li>Collaborated with UXD to deploy a FIGMA Accessibility Annotation kit, improving the transition of accessibility information from
                                        design to development, and resulting in a reduction of time spent between design and development.</li>
                                        <li>Worked directly with multiple stakeholders and provided project oversight on a multi-year $750 million plus value contract, to
                                        implement reporting and risk mitigation plans that eliminated 1,000 accessibility defects within a year, and creating a more
                                        seamless experience for customers and associate's.</li>
                                        <li>Provided strategic direction and multi-year objectives and road maps for the Accessibility Center of Excellence team.
                                        Managed team priorities and workstreams, supervised process updates to stakeholders, managed budgets for the team and
                                        external vendor support.</li>
                                    </ul>
                                    */}
                                    <p>Still typing...</p>
                                </div>
                                </Typography>
                            </Modal.Body>
                            <Modal.Footer className="bg-white">
                                <Button onClick={() => setOpenModalAMC(false)} className="bg-vintage-blue inline-flex text-vintage-babyblue border-0 py-2 px-6 hover:bg-vintage-babyblue hover:text-vintage-dblue focus:bg-vintage-babyblue focus:text-vintage-dblue rounded text-lg">Okay</Button>
                            </Modal.Footer>
                        </Modal>
                    </Card>
                 </div>
                </div>
        </section>
    );
  }