import React from "react";

var Story = require('../image/Story.jpeg');
var Blocks = require('../image/Love.png');
var Sign = require('../image/HoldSign.jpeg');

export default function AboutNew() {
    return (
        <section id="about" className="text-vintage-dblue bg-white">
            <div className="container mx-auto pl-4 pr-4">
                <h1 className="title-font text-4xl md:text-3xl mb-4 font-medium text-vintage-dblue pt-24">About Me</h1>
            </div>
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 pl-4 pr-4 pb-4">
                <div><img alt="My story typed out on a typewriter" src={Story}/></div>
                <div className="content-center">
                <h2 className="leading-relaxed text-lg md:text-2xl text-vintage-dblue pb-4">My Story</h2>
                    <p className="leading-relaxed text-lg md:text-lg text-vintage-dblue">Meet me, Eric Lyons! I live just outside our Nation’s Capital in Rockville, MD. 
                    I went to school at West Virginia University (go Mountaineers!), and graduated with a Bachelor’s Degree in Public Relations, 
                    and I’m fluent in English y en Espanol.</p> 
                    <p className="leading-relaxed text-lg md:text-lg text-vintage-dblue pt-4">I am a Digital Accessibility Leader and Design Strategist with over 10 years of 
                        experience driving accessibility-focused design and development. My goals are to empower teams to build inclusive user experiences by 
                        providing them with the knowledge, tools, and strategies to prioritize accessibility at every step.</p>
                   </div>
                <div>
                    <h2 className="leading-relaxed text-lg md:text-2xl text-vintage-dblue pb-4">What Makes me Awesome</h2>
                    <p className="mb-8 leading-relaxed text-lg md:text-lg text-vintage-dblue">I mean me being me makes me awesome! But from an experience perspective:
                        I am a Digital Accessibility Leader and Design Strategist with over 10 years of experience driving accessibility-focused design and development. My goals are to empower teams to build inclusive user experiences 
                        by providing them with the knowledge, tools, and strategies to prioritize accessibility at every step.</p>
                    <p className="mb-8 leading-relaxed text-lg md:text-lg text-vintage-dblue">Throughout my career, I've had the privilege of working with large and complex 
                        organizations such as USAA, Fidelity, Google, Capital One, and College Board, as well as collaborating with federal and state 
                        governments. My expertise spans creating and refining accessibility processes that not only improve usability but also enhance 
                        operational efficiency.</p>
                    <p className="mb-8 leading-relaxed text-lg md:text-lg text-vintage-dblue">I’m passionate about continuing to push the boundaries of accessible UX, and am looking for opportunities to leverage my experience in 
                        process improvement and strategic leadership. Let’s connect and discuss how I can help your team create accessible, user-centered 
                        experiences!</p>
                </div>
                <div className="content-center"><img alt="My story in blocks" src={Sign}/></div>
            </div>
    </section>
    );

}