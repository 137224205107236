import React from "react";
import { 
    FaAccessibleIcon,
    FaUsers,
    FaSchool,
    FaCheckDouble,
    FaPeopleArrows,
    FaMapMarked,
    FaThumbsUp,
    FaSitemap,
    FaPalette,
    FaChartPie,
    FaMapMarkedAlt,
    FaAppStoreIos } from 'react-icons/fa';

export default function Skills() {
    return (
        <section id="skills" className="text-vintage-dblue bg-white body-font grid flex pb-12">
             <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <div className="text-vintage-dblue bg-white p-3 md:col-span-2 lg:col-span-3">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-vintage-dblue pt-24">
                        Skills I've Learned Over the Years
                    </h1>
                        <p className="mb-8 leading-relaxed sm:text-lg text-base pt-8">Hope you liked my resume. Always a good idea to take a walk down a person's work history, to learn about their experiences and what they have brought to their previous employers. But what about their skills? Not always reflected in the resume, so peep my skills below. Work history is my experience. My skills make up the rest of me.</p>
                </div>
                <div className="mt-4 w-96 text-center">
                    <div color="black" className="iconCenter text-5xl text-vintage-dblue">
                        <FaAccessibleIcon />
                        </div>
                        <h3 class="font-bold text-2xl">
                        <span class="bg-clip-text text-vintage-dblue"> Accessibility </span>
                        </h3>
                        <p className="text-base mt-0.5 text-vintage-dblue text-left">
                            At the core of everything I have built my career around, accessibility continues to be a priority in everything I do. 
                            WCAG, Assitive Technologies and process improvements to name a few.</p>
                </div>
                <div className="mt-4 w-96 text-center">
                    <div color="black" className="iconCenter text-5xl text-vintage-dblue">
                        <FaUsers />
                        </div>
                        <h3 class="font-bold text-2xl">
                        <span class="bg-clip-text text-vintage-dblue"> User Experience </span>
                        </h3>
                        <p className="text-base mt-0.5 text-vintage-dblue text-left">
                        How we create the experiences our users interact with and how we ensure we are giving them what they want is such an important part of
                        what I look for in the next stop in my career.
                        </p>
                </div>
                <div className="mt-4 w-96 text-center">
                    <div color="black" className="iconCenter text-5xl text-vintage-dblue">
                        <FaSchool />
                        </div>
                        <h3 class="font-bold text-2xl">
                        <span class="bg-clip-text text-vintage-dblue"> Training </span>
                        </h3>
                        <p className="text-base mt-0.5 text-vintage-dblue text-left">
                        Training is vital to ensuring employees and customers have the knowledge they need to do their jobs or use the products better. Plus I 
                        love creating AND delivering training programs!</p>
                </div>
                <div className="mt-4 w-96 text-center">
                    <div color="black" className="iconCenter text-5xl text-vintage-dblue">
                        <FaChartPie />
                        </div>
                        <h3 class="font-bold text-2xl">
                        <span class="bg-clip-text text-vintage-dblue"> Program Management </span>
                        </h3>
                        <p className="text-base mt-0.5 text-vintage-dblue text-left">
                        Defining business objectives and then aligning programs to support those objecives is gratifying work. Seeing an organziation be successful
                        thanks to the direction I give is my own personal pat on the back!
                        </p>
                </div>
                <div className="mt-4 w-96 text-center">
                    <div color="black" className="iconCenter text-5xl text-vintage-dblue">
                        <FaMapMarkedAlt />
                        </div>
                        <h3 class="font-bold text-2xl">
                        <span class="bg-clip-text text-vintage-dblue"> Process Implementation and Optimization </span>
                        </h3>
                        <p className="text-base mt-0.5 text-vintage-dblue text-left">
                        Zooming out and looking at how an organization approaches the experience lifecycle, identifying the gaps, and strategizing on how to make it more
                         efficient is what drives me. Process done right has so many benefits!</p>
                </div>
                <div className="mt-4 w-96 text-center">
                    <div color="black" className="iconCenter text-5xl text-vintage-dblue">
                        <FaPeopleArrows />
                        </div>
                        <h3 class="font-bold text-2xl">
                        <span class="bg-clip-text text-vintage-dblue"> Servant Leadership </span>
                        </h3>
                        <p className="text-base mt-0.5 text-vintage-dblue text-left">
                        I believe the strength of a team begins and ends with its leader, and managing teams is something 
                        that I pride myself in doing with passion and drive.</p>
                </div>
                <div className="mt-4 w-96 text-center">
                    <div color="black" className="iconCenter text-5xl text-vintage-dblue">
                        <FaPalette />
                        </div>
                        <h3 class="font-bold text-2xl">
                        <span class="bg-clip-text text-vintage-dblue"> Design Systems </span>
                        </h3>
                        <p className="text-base mt-0.5 text-vintage-dblue text-left">
                        I've been fortunate enough to support accessibility of Design Systems at two firms, and have been able to see how to approach, manage and execute on building them out.</p>
                </div>
                <div className="mt-4 w-96 text-center">
                    <div color="black" className="iconCenter text-5xl text-vintage-dblue">
                        <FaAppStoreIos />
                        </div>
                        <h3 class="font-bold text-2xl">
                        <span class="bg-clip-text text-vintage-dblue"> Software </span>
                        </h3>
                        <p className="text-base mt-0.5 text-vintage-dblue text-left">
                        Take your pick: I love them all! Adobe Creative Cloud, Figma, Miro, Visual Studio, Final Cut Pro, Microsoft Office</p>
                </div>
                <div className="mt-4 w-96 text-center">
                    <div color="black" className="iconCenter text-5xl text-vintage-dblue">
                        <FaSitemap />
                        </div>
                        <h3 class="font-bold text-2xl">
                        <span class="bg-clip-text text-vintage-dblue"> Key Stakeholder Management </span>
                        </h3>
                        <p className="text-base mt-0.5 text-vintage-dblue text-left">
                        Making sure the right people have the right amount of knowledge allows for decisions to be made and perspectives aligned. Just as important is being able to read the room and bring those people together.
                        </p>
                </div>
                </div>
                </div>
        </section>
    );
}